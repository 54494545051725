// extracted by mini-css-extract-plugin
export var backgroundImage = "contact-module--backgroundImage--63787";
export var chairmanTitle = "contact-module--chairmanTitle--dd86c";
export var contactBackground = "contact-module--contactBackground--c2eff";
export var contactDataTitle = "contact-module--contactDataTitle--662ef";
export var contactTitle = "contact-module--contactTitle--0d428";
export var facebookLink = "contact-module--facebookLink--ebeea";
export var inner = "contact-module--inner--4a3ae";
export var innerPartners = "contact-module--innerPartners--a2954";
export var leftLine = "contact-module--leftLine--35ba7";
export var leftSide = "contact-module--leftSide--e2e36";
export var leftText = "contact-module--leftText--99033";
export var logo = "contact-module--logo--f7e4c";
export var partnersBackground = "contact-module--partnersBackground--5290d";
export var partnersTitle = "contact-module--partnersTitle--2c7a3";
export var rightLine = "contact-module--rightLine--a6978";
export var rightSide = "contact-module--rightSide--d6525";
export var rightText = "contact-module--rightText--ce426";
export var supervisorTitle = "contact-module--supervisorTitle--1cba7";
import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import * as contactStyles from "../components/contact.module.css"
import Header from "../components/header"
import SEO from "../components/seo"
import { scale } from "../utils/typography"

const site_title="Kontakt"
const partners_title="Partnerzy"
const supervisor_title="Opiekun naukowy Koła"
const supervisor="dr hab. Tomasz Błachowicz"
const supervisor_email="Tomasz.Blachowicz@polsl.pl"
const chairman_title="Prezes Koła"
const chairman="Mateusz Rosa"
const chairman_email="mr310738@student.polsl.pl"
const contact_data_title="Dane kontaktowe"
const email_title="E-mail"
const email="skn.sat@polsl.pl"
const facebook_title="Facebook"
const facebook="facebook.com/silesianaerospacetechnologiespolsl"
const facebook_link = "https://www."+facebook
const address_title="Adres"
const address="Stanisława Konarskiego 22b 41-100 Gliwice"

const Contact = () => {
  const data = useStaticQuery(graphql`
  query {
    background: file(relativePath: {eq: "contact-background.png"}) {
      childImageSharp {
        fluid(maxWidth: 2546, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: {eq: "sat-logo-horizontal.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    partners_logos: allFile(filter: {
        relativeDirectory: {eq: "logos-black"}, 
        extension: {regex: "/(png)/"}
      }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
  }
  `)

  console.log(data.partners_logos);

  return (
    <>
      <Header siteTitle={site_title} />
      <SEO title={site_title} />
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>
          <Img 
            className={contactStyles.backgroundImage}
            fluid={data.background.childImageSharp.fluid} 
          />
          <Img 
            className={contactStyles.logo}
            fluid={data.logo.childImageSharp.fluid} 
          />
          <div className={contactStyles.contactTitle}>
            <h1
              style={{
                ...scale(2),
                margin: `0`
              }}
            > 
                {site_title}
            </h1>
          </div>
          <div className={contactStyles.contactBackground}>
            <div className={contactStyles.leftSide}>
              <div className={contactStyles.leftLine} />
              <div className={contactStyles.supervisorTitle}>
                {supervisor_title}
              </div>
              <div className={contactStyles.leftText}>
                <p>
                  {supervisor}<br/>
                  {supervisor_email}
                </p>
              </div>
              <div className={contactStyles.leftLine} />
              <div className={contactStyles.chairmanTitle}>
                {chairman_title}
              </div>
              <div className={contactStyles.leftText}>
                <p>
                  {chairman}<br/>
                  {chairman_email}
                </p>
              </div>
            </div>
            <div className={contactStyles.rightSide}>
              <div className={contactStyles.contactDataTitle}>
                {contact_data_title}
              </div>
              <div className={contactStyles.rightLine} />
              <div className={contactStyles.rightText}>
                <p>
                  <b>{email_title}</b><br/>
                  {email}
                </p>
                <p>
                  <b>{facebook_title}</b><br/>
                  <Link className={contactStyles.facebookLink}
                        to={facebook_link}>
                          {facebook}
                  </Link>
                </p>
                <p>
                  <b>{address_title}</b><br/>
                  {address}
                </p>
              </div>

            </div>
          </div>
          <div className={contactStyles.partnersTitle}>
            <h1
              style={{
                ...scale(2),
                margin: `0`
              }}
            > 
                {partners_title}
            </h1>
          </div>
          <div className={contactStyles.partnersBackground}>
            <div className={contactStyles.innerPartners}>
              {data.partners_logos.edges.map((edge) => {
                return (
                  <Img fixed={edge.node.childImageSharp.fixed}/>
                )
              })}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}


export default Contact
